import React from 'react';
import './index.css';

const App = () => {
  const [inputValue, setInputValue] = React.useState('');
  const [base64Encoded, setBase64Encoded] = React.useState('');
  const [decodedValue, setDecodedValue] = React.useState('');

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  
  const handleBase64Encode = () => {
    if (inputValue) {
      const base64EncodedValue = btoa(inputValue);
      setBase64Encoded(base64EncodedValue);
    }
  };

  const handleBase64Decode = () => {
    if (base64Encoded) {
      const decodedValue = atob(base64Encoded);
      setDecodedValue(decodedValue);
    }
  };

  return (
    <div className="bg-gray-900 min-h-screen flex items-center justify-center">
      <div className="max-w-md w-full p-8 bg-gray-800 rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold text-white mb-8 text-center">Chat with Base64 Encoder/Decoder</h1>
        <div className="flex flex-col items-center mb-8">
          <div className="w-full">
            <textarea
              value={inputValue}
              onChange={handleInputChange}
              placeholder="Enter text to encode/decode"
              className="bg-gray-700 text-white px-6 py-4 rounded-lg w-full mb-6 focus:outline-none focus:ring-2 focus:ring-blue-500 text-lg h-32 resize-none"
            ></textarea>
          </div>
          <div className="flex justify-center">
            <button
              onClick={handleBase64Encode}
              className="bg-green-500 hover:bg-green-600 text-white font-bold py-4 px-10 rounded-lg mr-4 text-lg"
            >
              Encode
            </button>
            <button
              onClick={handleBase64Decode}
              className="bg-purple-500 hover:bg-purple-600 text-white font-bold py-4 px-10 rounded-lg text-lg"
            >
              Decode
            </button>
          </div>
        </div>
        <div className="text-white text-center">
          <div className="mb-2">
            <h3 className="text-xl font-bold mb-1">Base64 Encoded:</h3>
            <p className="text-lg">{base64Encoded}</p>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-2">Decoded Value:</h3>
            <p className="text-lg">{decodedValue}</p>
          </div>
        </div>
      </div>
      <div class="footer">
  <p>Made by ❤️ with <a href="http://shivastra.in/" target="_blank" rel="noreferrer">Shivam Kumar</a></p>
  <p class="copyright">All rights reserved &copy; 2024</p>
</div>

    </div>
  );
};

export default App;